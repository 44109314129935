<template>
  <div class="mb-5">
    <vx-card>
    <vs-row>
      <vs-col vs-w="7" class="pr-5">
        <div>
        <label for="name">Plan name</label>
        <vs-input
          class="w-7/12"
          v-validate="'required|max:100'"
          size="large"
          maxLength="100"
          v-model="formData.planName"
          id="name"
          name="name"
        />
          <span
            class="text-danger text-sm"
            v-show="errors.has('name')"
          >{{ errors.first("name") }}</span>
        </div>

        <div class="inline-block smm:w-full mdm:w-full w-7/12 mr-5">
          <label class="w-full" for="feeModel">Fee model</label>

          <vs-select
            id="feeModel"
            class="w-full"
            name="feeModel"
            v-model="formData.feeModel"
          >
            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item , index) in feeModel" />
          </vs-select>

        </div>

        <div class="inline-block  smm:w-full mdm:w-full mr-2" style="width:18%">
          <label class="w-full" for="amount">{{ labelChange }}</label>
          <money
            name="amount"
            v-bind="money"
            :placeholder="labelChange"
            v-validate="'required||min_value:1'"
            class="w-full amount-ph"
            v-model="sfValue"
            id="amount"
          ></money>


        </div>

        <div class="inline-block smm:w-full mdm:w-full  " style="width: 18%">
          <label class="w-full" for="rebate">Rebate</label>
          <money
            name="rebate"
            placeholder="rebate"
            v-bind="money"
            v-validate="'required||min_value:1'"
            class="w-full amount-ph"
            v-model="formData.rebate"
            id="rebate"
          ></money>

        </div>

        <div class="inline-block  smm:w-full mdm:w-full w-7/12 mr-5">
          <label class="w-full" for="frequency">Payment frequency</label>
          <vs-select
            class="w-full"
            id="frequency"
            name="frequency"
            v-model="formData.paymentFrequency"
          >
            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item , index) in frequency" />
          </vs-select>
        </div>
        <div class="inline-block smm:w-full mdm:w-full" style="width: 37%">
          <label class="w-full" for="rebate">Number of payments</label>
          <money
            name="noOfPayments"
            v-bind="money2"
            v-validate="maxValidation"
            class="w-full amount-ph"
            v-model="noOfPayments"
            id="noOfPayments"
          ></money>
          <span
            class="text-danger text-sm block"
            v-show="errors.has('noOfPayments')"
          >{{ errors.first("noOfPayments") }}</span>

        </div>


        <div>
          <label class="w-full" for="name">Surcharge message</label>
          <vs-input
            v-model="formData.surchargeText"
            class="w-full"
            id="surcharge"
            name="surcharge"
            v-validate="'max:100'"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('surcharge')"
          >{{ errors.first("surcharge") }}</span
          >
        </div>

        <div>
          <label class="w-full" for="name">Flip account connector</label>
          <vs-select
            id="flipConnector"
            class="w-full"
            name="flipConnector"
            v-model="formData.flipConnector"
          >
            <vs-select-item :key="index" :value="item._id" :text="item.displayName" v-for="(item , index) in tillConnectors" />
          </vs-select>
          <span
            class="text-danger text-sm"
            v-show="errors.has('flipConnector')"
          >{{ errors.first("flipConnector") }}</span
          >
        </div>

      </vs-col>

      <vs-col vs-w="4" vs-offset="1">
        <div class="mt-5 mb-5">
        <h6 class="mb-5 font-semibold">
          Plan configuration with $1,000 purchase
        </h6>
        <div class="content">
          <p>
            {{displayedData.planText[0]}}
          </p>
          <p>
            {{displayedData.planText[1]}}
          </p>
          <p class="mb-5">{{displayedData.planText[2]}}</p>
          <p>
            <span class="underline">Customer</span> will pay: {{
              formatCurrency(displayedData.totalCustomerPayable)
            }}
          </p>
          <p>
            <span class="underline">Merchant</span> will receive: {{
              formatCurrency(displayedData.merchantAmount)
            }}
          </p>
        </div>
        </div>
        <vs-button
          @click="$route.query.id ? updatePaymentPlan() : addPaymentPlan()"
          class="w-2/4 smm:w-full mdm:w-full  mb-5"
          v-round
        >
          Save Changes
        </vs-button>
        <vs-button
         v-round
          @click="$router.go(-1)"
          class="ml-10 font-bold link-danger pr-5 danger primary"
        >
          Cancel
        </vs-button>
      </vs-col>

    </vs-row>
    </vx-card>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { mapActions, mapState } from 'vuex';
import _ from "lodash";
import { Money } from "v-money";
export default {
  components: {
    ValidationProvider,
    Money,
  },
  data() {
    return {
      isButtonHidden: true,
      sfValue: 0,
      surCharge: 0,
      frequency:[
        {text:'Daily',value:'Daily'},
        {text:'Weekly',value:'Weekly'},
        {text:'Fortnightly',value:'Fortnightly'},
        {text:'Monthly',value:'Monthly'}
      ],
      feeModel: [
        { text: 'MSF to merchant', value: 'MSFM' },
        { text: 'MSF to customer', value: 'MSFC' },
        { text: 'SF to Merchant, Surcharge to customer', value: 'SF' }],
      payables: {
        Daily: "daily",
        Weekly: "weekly",
        Fortnightly: "every 2 weeks",
        Monthly: "monthly",
      },
      noOfPayments: 2,
      money: {
        suffix: "%",
        precision: 2,
      },
      money2: {
        precision: 0,
      },
      formData: {
        planName: "",
        enabled: false,
        paymentFrequency: "",
        noOfPayments: "",
        feeModel: "",
        msf: "",
        sf: "",
        surchargeText: "",
        rebate: "",
        partnerId: "",
        flipConnector: "",
      },
      displayedData: {
        merchantAmount: '',
        totalCustomerPayable: '',
        planText: ""
      },
      tillConnectors: [],
    };
  },
  methods: {
    ...mapActions("connector", ["getAllAdminConnectors"]),
    goToPages() {
      this.$router.push({
        name: "partners-edit",
        params: { id: this.$route.params.partnerId },
      });
    },
    truncate(num) {
      return num.toFixed(3).slice(0, -1);
    },
    calculate() {
      const payload = {
        calculationData: {
          amount: "1000",
          paymentFrequency: this.formData.paymentFrequency,
          sfValue: this.sfValue.toString(),
          feeModel: this.formData.feeModel,
          noOfPayments: this.noOfPayments.toString(),
        },
      };
      this.$http
        .post("calculation", payload)
        .then((result) => {
          this.displayedData = result.data.message[0]
        })
        .catch((err) => {
          this.$vs.loading.close();
          
        });
    },
    addPaymentPlan() {
      this.$vs.loading();
      this.formData.sf = this.truncate(this.sfValue);
      this.formData.noOfPayments = this.noOfPayments.toString(),
      this.formData.rebate = this.truncate(this.formData.rebate);
      this.formData.msf = "N/A";
      if (this.formData.feeModel.includes("MSF")) {
        this.formData.msf = this.truncate(this.sfValue);
        this.formData.sf = "N/A";
      }
      this.$store
        .dispatch("paymentPlans/createPlan", this.formData)
        .then((result) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Successful",
            text: result.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",

          });
          this.goToPages();
        })
        .catch((err) => {
          this.$vs.loading.close();
          
        });
    },

    getPaymentPlanById(id, partnerId) {
      const params = {
        id,
        partnerId,
      };
      this.$store
        .dispatch("paymentPlans/getAPlan", params)
        .then((result) => {
          this.formData = _.pick(result.data.data, Object.keys(this.formData));
          this.sfValue = parseFloat(this.formData.sf);
          if (this.formData.feeModel.includes("MSF")) {
            this.sfValue = this.formData.msf;
            this.noOfPayments = this.formData.noOfPayments;
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          
          this.$vs.notify({
            title: "Error",
            text: err.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",

          });
        });
    },
    async getTillConnectors(){
      await this.getAllAdminConnectors().then((result) => {
        this.tillConnectors = result.data.data;
        
      })
    },
    updatePaymentPlan() {
      this.$vs.loading();
      this.formData.sf = this.truncate(this.sfValue);
      this.formData.rebate = this.truncate(this.formData.rebate);
      this.formData.noOfPayments = this.noOfPayments.toString(),
      this.formData.msf = "N/A";
      if (this.formData.feeModel.includes("MSF")) {
        this.formData.msf = this.truncate(this.sfValue);
        this.formData.sf = "N/A";
      }
      this.$store
        .dispatch("paymentPlans/updatePlan", {
          id: this.$route.query.id,
          data: this.formData,
        })
        .then((result) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Successful",
            text: result.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",

          });
          this.goToPages();
        })
        .catch((err) => {
          this.$vs.loading.close();
          
          this.$vs.notify({
            title: "Error",
            text: err.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",

          });
        });
    },
  },
  computed: {
    ...mapState("paymentPlans", ["paymentPlans"]),
    labelChange() {
      return this.formData.feeModel.includes("MSF") ? "MSF" : "SF";
    },
    maxValidation(){
      if(this.formData.paymentFrequency === 'Daily'){
         return 'required|min_value:2|max_value:61'
      }
      else if(this.formData.paymentFrequency === 'Weekly'){
        return 'required|min_value:2|max_value:8'
      }
      else if(this.formData.paymentFrequency === 'Fortnightly'){
        return 'required|min_value:2|max_value:5'
      }
      else{
        return 'required|min_value:2|max_value:3'
      }
    }
  },
  mounted() {
    this.formData.partnerId = this.$route.params.partnerId;
    if (this.$route.query.id) {
      this.$route.meta.pageTitle = "Edit payment plan";
      this.getPaymentPlanById(
        this.$route.query.id,
        this.$route.params.partnerId
      );
    }
    this.getTillConnectors();

  },
  watch: {
    noOfPayments(_newVal, _oldVal) {
        if(this.formData.feeModel!=="" && this.formData.paymentFrequency!=="" && this.sfValue!=="") {
          this.calculate();
        }
    },
    'formData.feeModel'(_newVal, _oldVal) {
      if(this.noOfPayments!=="" && this.formData.paymentFrequency!=="" && this.sfValue!=="") {
        this.calculate();
      }
    },
    'formData.paymentFrequency'(_newVal, _oldVal) {
      if(this.noOfPayments!=="" && this.formData.feeModel!=="" && this.sfValue!=="") {
        this.calculate();
      }
    },
    'sfValue'(_newVal, _oldVal) {
      if(this.noOfPayments!=="" && this.formData.feeModel!=="" && this.formData.paymentFrequency!=="") {
        this.calculate();
      }
    },

  },
};
</script>